var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    [_vm.block?.containerClass]: true,
  },style:({
    backgroundColor: _vm.block?.componentStyle?.backgroundColor,
    paddingTop: _vm.block?.componentStyle?.paddingTop + 'px',
    paddingBottom: _vm.block?.componentStyle?.paddingBottom + 'px',
  })},[_c('v-container',{staticClass:"m-container common-block-talk-team"},[_c('div',{staticClass:"d-flex flex-column flex-lg-row justify-space-between section__talk-to-team px-5 px-md-0 pt-10 pt-lg-0 pb-0 pb-sm-5 pb-lg-0"},[_c('div',{staticClass:"section__talk-to-team-container"},[_c('div',{staticClass:"team-container"},[_c('div',{staticClass:"talk-to-team__title pb-7"},[(_vm.block?.title?.length > 0)?_c('h2',{staticClass:"section__title"},[_vm._v(" "+_vm._s(_vm.block.title))]):_vm._e(),_vm._v(" "),(_vm.block?.titleNoWrap?.length > 0)?_c('h2',{staticClass:"section__title section__title--no-wrap"},[_vm._v("\n              "+_vm._s(_vm.block.titleNoWrap)+"\n            ")]):_vm._e()]),_vm._v(" "),_c('p',{staticClass:"section__subtitle mb-10"},[_vm._v(_vm._s(_vm.block.subtitle))]),_vm._v(" "),_c('v-btn',{staticClass:"homepage-hero__button",style:({padding: '16px 36px'}),attrs:{"color":"primary","depressed":"","height":"56"},on:{"click":_vm.talkToSalesClicked}},[_vm._v("\n            "+_vm._s(_vm.block?.button?.text)+"\n          ")])],1)]),_vm._v(" "),(_vm.block.image)?_c('div',[_c('nuxt-img',{staticClass:"team-img",attrs:{"width":"460","height":"356","src":_vm.block.image,"format":"webp","densities":"x1 x2","alt":"teamImage","loading":"lazy"}})],1):_vm._e()]),_vm._v(" "),_c('common-sales-dialog',{ref:"salesDialogRef",attrs:{"dialog-id":_vm.salesDialogId,"dialog-type":_vm.salesDialogType,"google-calendar-link":_vm.googleCalendarLink}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }