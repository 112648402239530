
import { defineComponent } from 'vue'
import CommonSalesDialog from './CommonSalesDialog.vue'
import { Block } from '@/domain/interfaces/BlockTypes'
import AppActions from '~/domain/constants/AppActions'
import { trackLinkedinAds } from '~/domain/helpers/helpers'

export default defineComponent({
  name: 'TalkToTeam',
  components: {
    CommonSalesDialog,
  },
  props: {
    block: {
      type: Object as () => Block,
      required: true,
    },
  },
  data() {
    return {
      salesDialogId: this.block.dialogId,
      salesDialogType: this.block.dialogType,
      googleCalendarLink: this.block?.button?.link
    }
  },
  methods: {
    talkToSalesClicked(): void {
      let label = '';

      if(this.block.location === 'pricing'){
        label='www_pricing_modal'
      }
      else if(this.block.location === 'homepage'){
        label='www_homepage_modal'
      }

      if(label){
        trackLinkedinAds('book-demo')
        this.$store.dispatch(AppActions.TRACK_SEGMENT_EVENT, {
        context: this,
        params: {
          name: 'Custom Quote - Intent',
          category: 'Micro conversion',
          action: 'Custom Quote Intent',
          label,
          misc: [
            {
              type: 'location',
              value: label,
            },
          ],
        },
      })
      }
      // @ts-ignore
      this.$refs.salesDialogRef?.talkToSalesClicked();
    },
  },
})
